<template>
  <div>
    <b-sidebar
      id="sidebar-withdrawal-details"
      sidebar-class="sidebar-lg"
      :visible="withdrawalDetailsSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateWithdrawalDetailsSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Detalhes da solicitação</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="m-2" v-if="withdrawal">
          <b-row>
            <b-col cols="6" class="mb-1">
              Data da solicitação:  
            </b-col>
            <b-col cols="6" class="mb-1" >
              {{ withdrawal.created_at | onlyDate}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Status da solicitação: 
            </b-col>
            <b-col cols="6" class="mb-1">
              {{ withdrawal.status | withdrawalStatus}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Valor:
            </b-col>
            <b-col cols="6" class="mb-1" >
              {{ withdrawal.amount | toCurrency}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Chave Pix:  
            </b-col>
            <b-col cols="6" class="mb-1" style="overflow-wrap: break-word;">
              {{ withdrawal.consultant.franchise_pix}}
            </b-col>
            <b-col cols="6" class="mb-1">
              Tipo da chave:  
            </b-col>
            <b-col cols="6" class="mb-1 text-capitalize" >
              {{withdrawal.consultant.franchise_pix? withdrawal.consultant.franchise_pix_type_key:''}} 
            </b-col>
            <b-col cols="6" class="mb-1">
              Comentário: 
            </b-col>
            <b-col cols="6" class="mb-1">
              <span v-html="withdrawal.comment"></span>
            </b-col>
           
          </b-row>
        </div>
        <div v-else class="d-flex justify-content-center m-2">
          <b-spinner variant="primary" />
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BButton, BFormInput, BFormTextarea, BCol, BRow, BSpinner
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Ripple from 'vue-ripple-directive'
import * as types from '../store/types'
import * as financialTypes from '@/modules/financial_management/store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCol,
    BRow,
    BSpinner
  },
  directives: { 
    Ripple,
  },
  data() {
    return {
      withdrawal: undefined
    }
  },
  setup() {
    return { toast: useToast()};
  },
  computed: {
    ...mapGetters({
      withdrawalDetailsSidebar: types.WITHDRAWAL_DETAILS_SIDEBAR
    })
  },
  methods: {
    ...mapMutations({
      mutateWithdrawalDetailsSidebar: types.MUTATE_WITHDRAWAL_DETAILS_SIDEBAR
    }),
    ...mapActions({
      getWithdrawalDetails: types.GET_WITHDRAWAL_DETAILS
    }),
    onShow(){
      this.getWithdrawalDetails(this.withdrawalDetailsSidebar.id)
        .then(response => {
          if(response.data.id){
            this.withdrawal = response.data
          }else{
            throw new DOMException("Report não encontrado.")
          }
          
        })
        .catch(err => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os detalhes. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
          this.hide
        })
    },
    clear(){
      this.withdrawal = undefined
    }
  }
}
</script>

<style lang="scss">
.sidebar-bottom {
  position: absolute;
  bottom: 0;
  width: -moz-available;          /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
</style>
